.react-calendar {
  max-width: 400px;
  margin: 0 auto;
  line-height: 1.125em;
}

.react-calendar abbr {
  text-decoration: none !important;
}

.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  margin: 0.5em 0;
}
.react-calendar__navigation button {
  min-width: 44px;
  border-radius: var(--chakra-radii-md);
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: var(--chakra-colors-gray-100);
}
.react-calendar__navigation button[disabled] {
  background-color: var(--chakra-colors-gray-50);
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
/* .react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
} */
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: var(--chakra-colors-gray-300);
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0;
  height: 2em;
  width: 2em;
  border-radius: var(--chakra-radii-lg);
  position: relative;
}
.react-calendar__tile:disabled {
  color: var(--chakra-colors-gray-300);
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  color: var(--chakra-colors-gray-600);
  background-color: var(--chakra-colors-gray-200);
}
.react-calendar__tile--now {
  font-weight: bold;
  color: var(--chakra-colors-teal-500);
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background-color: var(--chakra-colors-teal-100);
}
.react-calendar__tile--hasActive {
  background: var(--chakra-colors-teal-500);
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: var(--chakra-colors-teal-400);
}
.react-calendar__tile--active {
  background: var(--chakra-colors-teal-500);
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: var(--chakra-colors-teal-500);
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

:root {
  --form-label-width: 80px;
}

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.chakra-ui-dark ::-webkit-scrollbar-thumb {
  background-color: var(--chakra-colors-gray-600);
}

.cb-editable {
  border: 1px solid transparent;
}

.cb-editable:hover {
  border: 1px dashed var(--chakra-colors-blue-400);
}

.cb-revealable .cb-revealable-item {
  opacity: 0;
}

.cb-revealable:hover .cb-revealable-item {
  opacity: 1;
}

.cb-revealable:focus .cb-revealable-item {
  opacity: 1;
}

.cb-truncate {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.transaction-tasks .task-parent {
  display: none;
}

.my-tasks .task-assignee {
  display: none;
}

.chakra-editable__input[value=''] + .chakra-editable__preview {
  opacity: 0.5;
  padding-left: 4px;
  padding-right: 4px;
  text-decoration: underline;
}
